<template>
  <div-wrapper className="retunlist">
    <div-wrapper className="filter-srch" v-if="orders.length">
      <Input
        iType="text"
        value=""
        name="search"
        :placeholder="$t('_searchHere')"
        className="input-srch"
        @input="getSearchInput"
      />
      <search-icon />
    </div-wrapper>
    <div-wrapper className="lot-titlebox" v-if="orders.length">
      <Span>
        {{ $t("_totalPacket") }}:<strong> {{ filteredList.length }} </strong>
      </Span>
      <Span>
        <strong
          >{{ $t("_scannedPacket") }}: {{ scannedMarkOrders.length }}</strong
        >
      </Span>
    </div-wrapper>
    <ul class="readyto-returnbox" v-if="orders.length">
      <li class="scan-mark" v-for="(order, index) in filteredList" :key="index">
        <div-wrapper className="infobox">
          <Span :txt="$t('_orderID')"> :</Span>
          <Span
            ><strong>{{ order.order_id }}</strong></Span
          >
        </div-wrapper>
        <div-wrapper
          className="infobox"
          v-if="order.order_delivery_date || order.order_return_date"
        >
          <Span :txt="$t('_returnDate')"> :</Span>
          <Span
            ><strong>{{
              new Date(
                order.order_delivery_date
                  ? order.order_delivery_date
                  : order.order_return_date
              ).toDateString()
            }}</strong></Span
          >
        </div-wrapper>
        <slot></slot>
        <Span class="check-mark" v-if="order.is_scanned" />
        <TagWithDispute :order="order" @raiseDispute="raiseDispute(order)" />
      </li>
    </ul>
    <no-order-msg v-else :message="$t('_noReturnOrders')" />
    <div-wrapper className="scantab-ftr" v-if="orders.length">
      <div-wrapper className="scan-tab" @handleDivClick="goToScanScreen">
        <span class="scan-elm">
          <scan-icon />
          <br />
          <span class="text-label">{{ $t("_scan") }}</span>
        </span>
      </div-wrapper>
    </div-wrapper>
    <div-wrapper className="scanned-ftr" v-if="orders.length">
      <button
        class="button"
        :disabled="!scannedMarkOrders.length"
        :style="
          !scannedMarkOrders.length
            ? 'color: #a2a2a2;border: 1px solid #b7b7b6;background: #d4cdc5;'
            : ''
        "
        @click="submitReturnOrders"
      >
        {{ $t("_returnOrder") }}
      </button>
    </div-wrapper>
    <Scanner
      v-if="isScannerOpen"
      @orderId="getOrderId"
      @closeScanner="isScannerOpen = !isScannerOpen"
    />
    <confirmation-modal
      v-if="isConfModalOpen"
      @action="getConfirmationAction"
      :btnTxt="$t('_yes')"
    >
      <div-wrapper className="confim-midcnt cancel-form" slot="body">
        <p>
          {{ confMessage }}
        </p>
        <div class="field-row">
          <label class="text-label">Seal No</label>
          <input
            type="text"
            class="input-style"
            name="seal_number"
            @change="handleSealNo"
          />
        </div>
      </div-wrapper>
    </confirmation-modal>
    <DisputeModal
      v-if="isDisputeModalOpened"
      :order="disputedOrder"
      @closeModal="isDisputeModalOpened = !isDisputeModalOpened"
    />
    <Popup :message="message" @handleClick="message = ''" />
    <Loader v-if="isLoading" />
  </div-wrapper>
</template>
<script>
import { getFilteredOrders } from "../../utils/OrderHelper";
import { GET, POST } from "../../utils/HttpStatusCodeConst";
// import { getDayNameByDate } from "../../utils/DateUtil";
import { BASE_API } from "../../utils/constants";
// import { canNotCreateLotToday } from "../../utils/Helper";
import ApiRequest from "../../services/ApiRequest";
import TagWithDispute from "../TagWithDispute";
import NoOrderMsg from "@/components/order/NoOrderMsg";
import DivWrapper from "@/components/form/DivWrapper";
import SearchIcon from "@/components/svgIcons/SearchIcon";
import ScanIcon from "@/components/svgIcons/ScanIcon";
import Loader from "@/components/commonUI/LoaderWrapper";
import Input from "@/components/form/InputWrapper";
import Span from "@/components/other/Span";
export default {
  components: {
    ConfirmationModal: () => import("@/components/modals/ConfirmationModal"),
    DisputeModal: () => import("../modals/DisputeModal"),
    Scanner: () => import("@/components/Scanner"),
    Popup: () => import("@/components/Popup"),
    TagWithDispute,
    NoOrderMsg,
    DivWrapper,
    SearchIcon,
    ScanIcon,
    Loader,
    Input,
    Span,
  },

  data() {
    return {
      isDisputeModalOpened: false,
      isConfModalOpen: false,
      isScannerOpen: false,
      disputedOrder: "",
      isLoading: false,
      confMessage: "",
      seal_number: "",
      message: "",
      search: "",
    };
  },

  props: {
    orders: {
      type: Array,
    },
  },

  computed: {
    scannedMarkOrders() {
      return this.orders.filter((order) => order.is_scanned);
    },

    filteredList() {
      return getFilteredOrders(this.orders, this.search);
    },
  },

  methods: {
    handleSealNo(e) {
      this[e.target.name] = e.target.value;
    },

    raiseDispute(_order) {
      this.disputedOrder = _order;
      this.isDisputeModalOpened = !this.isDisputeModalOpened;
    },

    goToScanScreen() {
      // if (canNotCreateLotToday()) {
      //   this.$swal({
      //     text: this.$t("_lotCreationAllowedMsg"),
      //     icon: "info",
      //   });
      //   return;
      // }
      if (this.scannedMarkOrders.length > 49) {
        this.$swal({
          text: "Please create a lot of mark as scanned orders first",
          icon: "info",
        });
        return;
      }
      this.isScannerOpen = !this.isScannerOpen;
    },

    getSearchInput(_event) {
      this[_event.target.name] = _event.target.value;
    },

    getConfirmationAction(_action) {
      if (_action === "no") {
        this.isConfModalOpen = !this.isConfModalOpen;
        return;
      }
      const _orderIds = this.scannedMarkOrders.map(({ id }) => id).join(",");
      this.isLoading = true;
      let _url = `/partner91/pickuplotcreate`;
      // if (this.seal_number) {
      //   _url = _url + `&seal_number=${this.seal_number}`;
      // }
      ApiRequest(BASE_API, _url, POST, {
        orders: _orderIds,
        seal_number: this.seal_number
      })
        .then((_data) => {
          this.isLoading = false;
          this.isConfModalOpen = false;
          if (!_data.result.status) {
            this.$swal({
              text: _data.Message || this.$t("_orderReturnedErr"),
              icon: "success",
            });
          } else {
            this.$swal({
              text: _data.Message || this.$t("_ordersReturnSuccessfully"),
              icon: "success",
            });
            this.$emit("reloadReadyToReturnOrders");
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.isConfModalOpen = false;
          this.$swal({ text: this.$t("_orderDoesNotReturn"), icon: "error" });
        });
    },

    getOrderId(_orderId) {
      this.isLoading = !this.isLoading;
      ApiRequest(
        BASE_API,
        `/partner91/scanorder/${_orderId}?action=to_be_return`,
        GET
      )
        .then((_data) => {
          this.isLoading = false;
          this.isScannerOpen = false;
          if (!_data.status) {
            this.$swal({
              text: this.$t("_orderNotFound", {
                orderId: this._orderId,
              }),
              icon: "error",
            });
          } else {
            let _scannedInput = _orderId;
            if (_orderId === _data.order_details[0].rma_number) {
              _scannedInput = _data.order_details[0].order_id;
            }
            const _index = this.orders.findIndex(
              (order) => order.order_id === _scannedInput
            );
            this.$set(this.orders[_index], "is_scanned", true);
            this.$swal({
              text: this.$t("_orderMakedReturn", {
                orderId: this._orderId,
              }),
              icon: "success",
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.isScannerOpen = false;
          this.$swal({
            text: this.$t("_somethingWentWrongScanOrderAgain"),
            icon: "error",
          });
        });
    },

    submitReturnOrders() {
      // const notAllowedDays = ["Monday", "Tuesday", "Wednesday"];
      // if (notAllowedDays.includes(getDayNameByDate())) {
      //   this.$swal({
      //     text: this.$t("_lotCreationAllowedMsg"),
      //     icon: "info",
      //   });
      //   return;
      // }
      // if (this.scannedMarkOrders.length < 3) {
      //   this.$swal({
      //     text: "Please scan at least 3 orders to create a lot",
      //     icon: "info",
      //   });
      //   return;
      // }
      this.confMessage = this.$t("_lotConfirmation", {
        orderLength: this.orders.length,
        markOrder: this.scannedMarkOrders.length,
      });
      this.isConfModalOpen = !this.isConfModalOpen;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_return-order.scss";
</style>
