export const getSortedOrderByDate = (_orders) => {
  return _orders.sort(
    (_firstValue, _secondValue) =>
      new Date(
        _firstValue.orderDate
          ? _firstValue.orderDate
          : ""
      ) -
      new Date(
        _secondValue.orderDate
          ? _secondValue.orderDate
          : ""
      )
  );
};

export const getFilteredOrders = (_orders, searchText) => {
  let searchIn = [
    "order_id",
    "orderId",
    "customer_name",
    "customer_phone",
    "customer_address",
    "lot_id",
    "tracking_number",
    "lotState",
    "identifier",
  ];
  return _orders.filter((order) => {
    return searchIn.some(
      (field) =>
        Object.prototype.hasOwnProperty.call(order, field) &&
        order[field]
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
  });
};

export const getOrderIndex = (_orders, key, matchingOrderId) => {
  return _orders.findIndex((x) => x[key] === matchingOrderId);
};

export const raiseDispute = (_order, _this) => {
  _this.isModalOpened = !_this.isModalOpened;
};

export const getOrderTatExceededText = (date) => {
  if(date==null){
    return {
      "txt":"Deliver this order",
      class: "outof-time",
    }
  }
  const time = timeDiffCalc(new Date(date), new Date());

  if (time.days >= 3) {
    return {
      txt: `You are running out of delivery date`,
      class: "outof-time",
    };
  }
  if (time.days > 1 && time.days < 3) {
    return {
      txt: `Deliver this order within ${3 - time.days} ${
        time.days === 1 ? "day" : "days"
      }`,
      class: "in-time",
    };
  } else if ((time.days == 0 && time.hours <= 24) || time.days == 1) {
    var fdate = new Date(date);
    // add a day
    fdate.setDate(fdate.getDate() + 2);
    const atime = timeDiffCalc(fdate, new Date());
    return {
      txt: `Deliver this order within ${atime.days} days ${atime.hours} hours ${
        atime.minutes
      } ${
        time.minutes === 0 || time.minutes === 1 ? "minute" : "minutes"
      } and get 2 Rs. extra`,
      class: "before-time",
    };
  }
};

export const getCallingOrderDeadlineTxt = (date) => {
  const time = timeDiffCalc(new Date(date), new Date());
  if (time.days > 0 && time.days < 3) {
    return {
      txt: `Call to this order within ${time.days} ${
        time.days === 1 ? "day" : "days"
      }`,
      class: "in-time",
    };
  }
  if (time.hours <= 24) {
    return {
      txt:
        "Call this customer and in case of successful delivery of this order you can earn Rs. 5 extra.",
      class: "before-time",
    };
  }
};

export const timeDiffCalc = (dateFuture, dateNow = new Date()) => {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  return { days, hours, minutes };
};
