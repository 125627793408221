<template>
  <div-wrapper class="tag_block">
    <span v-if="order.is_missing" class="flag-tag">{{ $t("_missing") }}</span>
    <span
      class="flag-tag"
      :style="{ background: '#ff9933' }"
      v-if="order.is_deny_redispatch"
      >{{ $t("_forRedispatch") }}</span
    >
    <span
      class="flag-tag"
      :style="{ background: '#ff9933' }"
      v-if="order.is_return"
      >{{ $t("_returnPackage") }}</span
    >
    <slot></slot>
    <span
      v-if="isDisputeBtnVisible"
      class="button dispute-btn"
      @click.prevent="$emit('raiseDispute')"
      >{{ $t("_raiseDispute") }}</span
    >
  </div-wrapper>
</template>
<script>
import DivWrapper from "@/components/form/DivWrapper";

export default {
  components: {
    DivWrapper,
  },

  props: {
    order: {
      type: Object,
      default: null,
    },
    isDisputeBtnVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.redispatch-flag {
  background: #ff9933;
  margin-left: 8px;
}
</style>
