<template>
  <div-wrapper class="return-ordlistcnt">
    <header class="inner-head">
      <div class="left-col">
        <Span class="icon-btn goback" @handleClick="goBack">
          <back-icon />
        </Span>
        <h1>{{ $t('_returnOrders') }}</h1>
      </div>
      <div-wrapper class="rgt-iconbox">
        <span @click="showHelpModal = true" class="icon-Btn help-btn">
          <help-icon />
        </span>
      </div-wrapper>
    </header>
    <!-- <ul class="return-tab">
      <li
        :class="['ctgtab', selectedTab === 'under_processing' && 'select-tab']"
        @click.stop="toggleTab('under_processing')"
      >
        {{ $t("_underProcessing") }}
      </li>
      <li
        :class="['ctgtab', selectedTab === 'ready_to_return' && 'select-tab']"
        @click.stop="toggleTab('ready_to_return')"
      >
        {{ $t("_readyToReturn") }}
      </li>
    </ul> -->
    <section class="mid-cnt">
      <!-- <under-processing-return-orders
        :orders="orders"
        v-if="selectedTab === 'under_processing'"
      /> -->
      <ready-to-return-orders
        :orders="orders"
        @reloadReadyToReturnOrders="getReturnOrders('ready_to_return')"
      />
    </section>
    <help-modal
      @closeModal="showHelpModal = false"
      :text="returnOrdersHelpText"
      v-if="showHelpModal"
    ></help-modal>
    <Popup :message="message" @handleClick="message = ''" :color="color" />
    <Loader v-if="isLoading" />
  </div-wrapper>
</template>
<script>
import { getSortedOrderByDate } from '../utils/OrderHelper';
import { BASE_API } from '../utils/constants';
import { GET } from '../utils/HttpStatusCodeConst';
import ApiRequest from '../services/ApiRequest';
import { _toggleTab } from '../utils/Helper';
// import UnderProcessingReturnOrders from "@/components/order/UnderProcessingReturnOrders";
import ReadyToReturnOrders from '@/components/order/ReadyToReturnOrders';
import DivWrapper from '@/components/form/DivWrapper';
import BackIcon from '@/components/svgIcons/BackIcon';
import Loader from '../components/commonUI/LoaderWrapper';
import Span from '@/components/other/Span';
import Popup from '@/components/Popup';
import HelpModal from '@/components/modals/HelpModal';
import HelpIcon from '@/components/svgIcons/HelpIcon';
export default {
  components: {
    // UnderProcessingReturnOrders,
    ReadyToReturnOrders,
    DivWrapper,
    BackIcon,
    Loader,
    Span,
    Popup,
    HelpIcon,
    HelpModal,
  },

  data() {
    return {
      selectedTab: 'ready_to_return',
      isLoading: false,
      orders: [],
      message: '',
      color: '',
      showHelpModal: false,
      returnOrdersHelpText: this.$t('_returnOrdersHelpText'),
    };
  },

  created() {
    if (this.$route.query.type) {
      this.selectedTab = this.$route.query.type;
    }
    this.getReturnOrders(this.selectedTab);
  },

  methods: {
    toggleTab(_selectedTab) {
      _toggleTab(this, _selectedTab);
      this.getReturnOrders(_selectedTab);
    },

    goBack() {
      this.$router.back(-1);
    },

    getReturnOrders(_type) {
      this.isLoading = !this.isLoading;
      ApiRequest(
        BASE_API,
        `/partner91/getorders/?order_type=${_type}${
          _type === 'ready_to_return' ? '&is_return=true' : ''
        }`,
        GET
      )
        .then((_data) => {
          // console.log(_data);
          this.isLoading = !this.isLoading;
          this.orders = getSortedOrderByDate(_data);
          this.$forceUpdate();
        })
        .catch(() => {
          this.isLoading = !this.isLoading;
          this.$swal({ text: this.$t('_somethingWentWrong'), icon: 'error' });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_return-order.scss';
</style>
